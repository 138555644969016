.package {
    margin-top: 30px;
    .package__name {
        font-size: 23px;
        font-weight: bold;
        text-align: center;
    }
    .package__information {
        display: grid;
        grid-gap: 15px;
        margin-top: 25px;
        grid-template-rows: 1fr 1fr;
        .package__validity_period, .package__price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 20px;
                &:last-child {
                    font-weight: bold;
                }
            }
        }
    }
    .package__description {
        margin-top: 50px;
        p {
            font-size: 19px;
        }
    }
    .package__form__communication {
        margin-top: 60px;
        h2 {
            width: 190px;
            font-size: 23px;
            font-weight: bold;
            text-align: left;
        }
        .package__form__communication__info {
            display: grid;
            grid-gap: 10px;
            grid-template-rows: auto;

            .package__service {
                gap: 12px;
                display: flex;
                margin-top: 25px;
                align-items: center;
                p {
                    font-size: 15px;
                    font-weight: bold;
                }
                .package__service__image {
                    width: 45px;
                    height: 45px;
                    position: relative;          
                    border-radius: 50%;
                    background: #74aafb75;
                    svg {
                        top: 50%;
                        left: 50%;
                        font-size: 26px;
                        position: absolute;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}